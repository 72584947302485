// Most of these were copied from https://assets.adobedtm.com/launch-ENfabe4aff7fd348db959a23445bf6f6da.min.js
export const EventType = {
  other: "Other",
  referral: "Referral",
  conversion: "Conversion",
  custom: "Custom",
  pagedwelltiming: "Page Dwell Timing",
  Acquisition: "Acquisition: Mktg Opt ins",
  Acquisitionout: "Acquisition: Mktg Opt out",
  addthisshare: "Add this Share",
  applicationPlay: "Application Plays",
  articleClick: "Article Click",
  chat: "Chat",
  purchase: "Click to Purchase",
  comments: "Comments",
  contestEntries: "Contest Entries",
  donation: "Donations",
  downloads: "Downloads",
  email: "Email",
  followOntwitter: "Follow on Twitter",
  ftap: "FTAF",
  gameDownloads: "Game Downloads",
  gamelevelsplayed: "Game Levels Played",
  gamePlay: "Game Plays",
  gamecomplete: "Game Completes",
  instantwins: "Instant Wins",
  like: "Like",
  pinterst: "Pinterest",
  print: "Print",
  productInfo: "ProductInfo",
  purchaseIntendcoupon: "Purchase Intend: Coupon Requests",
  purchaseIntendsample: "Purchase Intend: Sample Requests",
  purchaseOrder: "Purchase: Order",
  ratingreview: "Rating/Review",
  recipeClick: "Recipe Click",
  recommend: "Recommend",
  registration: "Registration",
  share: "Share",
  signIns: "Sign Ins",
  siteSearch: "Site Search",
  submission: "Submission",
  suscribeUpdate: "Subscribe for Updates",
  suscribeNewsletter: "Subscriptions - Newsletter Sign up",
  tweet: "Tweet",
  uploads: "Uploads",
  videoPlays: "Video Plays",
  videoCompletes: "Video Completes",
  widgets: "Widgets",
  trackEvent: "trackEvent",
  trackEventnewslettersignup: "trackEventnewslettersignup",
  newsletter: "newsletter",
  signup: "signup",
  readMore: "Read More",
  pageScroll: "Page Scroll",
  promotionView: "promotionView",
  promotionClick: "promotionClick",
  productImpression: "productImpression",
  productclick: "productClick",
  removecart: "removecart",
  checkoutenhanced: "checkoutenhanced",
  filter: "Filter",
  productQuickView: "Product Quick View",
  shopNow: "ShopNow",
  videoProgress: "Video Progress",
  editorPick: "Editors Pick",
  mayLike: "You May Also Like",
  dropDown: "Drop Down",
  tags: "Tags",
  relatedArticle: "Related Article",
  loadMore: "Load More",
  addtoCart: "addtoCart",
  accordianClicked: "Accordian Clicked",
  anchorLinkClicked: "Anchor Link Clicked",
  outboundLinkClicked: "Outbound Link Clicked",
  featuredcategory: "Featured Category",
  contactus: "Contact-Us",
  storeLocator: "Store Locator",
  quickPoll: "Quick Poll",
  rangePDP: "Product Range-PDP",
  countryselected: "Country Selected",
  socialcommunity: "Social Community",
  buttonclicked: "Button Clicked",
  featuredsocialtopic: "Featured-social-topic",
  featuredtweetTab: "Featured Tweet Tab",
  multiplerelatedtweets: "Multiple Related Tweets",
  dynamicerror: "Dynamic Error",
  personalizegift: "Personalize Gift",
  leftrightquickview: "Left right Quickview",
  clickstosocialplatforms: "Clicks to Social Platforms",
  followaccount: "Follow Account",
  spincarousel: "Spin Carousel",
  changecontentfocus: "Change Content Focus",
  closecontentfocus: "Close Content Focus",
  bazaaarvoicerenders: "Bazaar Voice Renders",
  bazaarvoicereviewopen: "Bazaar Voice Review Open",
  bazaarvoicereformcloses: "Bazaar Voice Review Close",
  bazaarvoiceratingreview: "Bazaar Voice rating Review Submitted",
  purchaseenhanced: "purchaseenhanced",
  addtobasket: "Add To Basket",
  deleteproduct: "Delete Product",
  ordersummary: "Order Summary",
  inputerror: "Input Error",
  bvopen: "Bazaar Voice Rating/Review Open",
  bvclose: "Bazaar Voice Rating/Review Close",
  continueShopping: "Continue Shopping",
  miniBag: "Mini Bag",
  bvrenders: "BazaarVoice Renders",
  viewBag: "View Bag",
  turnto: "Turn To",
  virtualagent: "Virtual Agent",
  survey: "Survey",
  productawards: "Product Awards",
  imageclick: "Image Click",
  smartlabelExit: "Smart Label Exit",
  smartlabelClick: "Smart Label Click",
  profileEdit: "Profile Edit",
  retailerClick: "Retailer Click",
  reviewHelpful: "Review Helpful",
  sortReviews: "Sort Reviews",
  readReviews: "Read reviews",
  linkClick: "Link Click",
  clicktoaction: "Click To Action",
  diagtoolcta: "Diag Tool CTA",
  campaign: "Campaign",
  languageSelector: "Language Selector",
  storesearch: "Store Search",
  carouselClick: "Carousel Click",
  pagination: "Pagination",
  couponSelected: "Coupon Selected",
  ExternalLink: "External Link",
  socialcomponentcta: "Social Component CTA",
  imageload: "Image Upload",
  forms: "Forms",
  iFrame: "iFrame",
  tabClick: "Tab Click",
  viewonyoutube: "View on Youtube",
  diagtoolemail: "Diag Tool Email",
  rrRenders: "Rating Review Renders",
  rrOpen: "Rating Review Open",
  rrClose: "Rating Review Close",
  rrHelpful: "Rating Review Helpful",
  rrNotHelpful: "Rating Review Not Helpful",
  rrReported: "Rating Review Reported",
  storesearchrefresh: "Store Search Refreshed (V2)",
  AttentionPing: "Attention Time",
  coupon: "Coupons",
  campstep: "Campaign Step",
  audioPlays: "Audio Play",
  audioProgress: "Audio Progress",
  audioCompletes: "Audio Completes",
  intendToSignup: "Intend To Sign Up",
  unmute: "Unmute",
  mute: "Mute",
  clicktopurchasebundle: " Click To Purchase Bundle",
  videoAutoPlays: "Video Auto Play",
  VideoFastForwarded: "Video Fast Forwarded",
  RedeemCoupons: "Redeem Coupons",
  DiagnosticToolResults: "Dignostic Tool Results",
  viewPrize: "View Prize",
  clickToRecipe: "Click To Recipe",
  cartview: "Cart View",
  RegistrationStart: "Registration Start",
  SignUpstart: "SignUp Start",
  SignUpSubmit: "SignUp Submit",
  articleView: "Article View",
  engagement: "Engagement",
  advocacy: "Advocacy",
  quickPollSubmit: "Quick Poll Submit",
  quickPollLaunch: "Launch Quick Poll",
  calltoaction: "Call To Action",
  diagtooleve: "diagtooleve",
  ForgetMe: "Forget Me",
  RememberMe: "Remember Me",
  PasswordReset: "Password Reset",
  SignOut: "Sign Out",
  register: "Register",
  campstart: "COF-Campaign Start",
  next: "COF-Next",
  previous: "COF-Previous",
  reset: "COF-Reset",
  headerLink: "Header Link",
  qaSubmit: "qaSubmit",
  qaStart: "COF-QA Start",
  surveyStart: "Survey Start",
  RequestSample: "COF- Request Sample",
  SampleLaunch: "COF- Launch Sample",
  OutOfStock: "Out Of Stock",
  checkoutenhancedsteps: "checkoutenhancedsteps",
  micrositecampaign: "micrositecampaign",
  addFavourite: "Add Favourite",
  refundenhanced: "refundenhanced",
  trackAjaxPageLoad: "trackAjaxPageLoad",
  interest: "Interest",
  read: "Read",
  lead: "Lead",
  filterLooks: "FilterLooks",
  shopStory: "ShopStory",
};

export interface Event {
  eventInfo: {
    type: string;
    eventAction?: string;
    eventLabel?: string;
    eventValue?: number;
  };
  category?: {
    primaryCategory: string;
  };
  subcategory?: string;
  attributes?: {
    nonInteractive: {
      nonInteraction: number;
    };
  };
}

export const pushEvent = (event: Event) => {
  // @ts-ignore
  if (window.digitalData && window.digitalData.event) {
    // @ts-ignore
    window.digitalData.event.push(event);
  }
};
