import { logEvent } from "./log-events";
import { EventType, pushEvent } from "./index";

export const event1 = (pathname = "") => {
  const aiGenerated = document.querySelector('meta[property="Samantha"]') ? true : false;

  const win = window as any;
  if (!win.digitalData.component) win.digitalData.component = [];

  win.digitalData.component.push({
    componentInfo: {
      componentID: `${aiGenerated ? 'Samantha Content' : (pathname ? pathname : location.pathname)}`,
      componentName: `${aiGenerated ? 'Samantha Content' : (pathname ? pathname : location.pathname)}`,
    },
  });

  const EventData = {
    eventInfo: {
      type: EventType.trackAjaxPageLoad,
      eventLabel: pathname ? pathname : location.pathname,
      eventValue: 1,
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: EventType.read, 
  }; 
  pushEvent(EventData);
  logEvent("Page load Event..", EventData);

};
