import { EventType, pushEvent } from "./index";

/**
 * Scroll event
 */

export const event67 = (text: string) => {
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.pageScroll,
      eventLabel: `${text}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    }
  });
};
