import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

/**
 * Link Click
 */
export const event2 = (e: MouseEvent) => {
  const link = (e.target as Element).closest('[data-analytics-event2]');
  // not an 'a', missing 'href' or is a hash/anchor ?
  if (!link) return;

  const text =  link.getAttribute("aria-label") || link.textContent?.trim();

  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.linkClick,
      eventLabel: `${text} - ${link?.href || ''}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    }
  };

  pushEvent(EventData);
  logEvent("Link Click", EventData);
};
