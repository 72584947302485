import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

export const event78 = (e: MouseEvent) => {
  const link = (e.target as Element).closest("[data-analytics-event78]") as HTMLAnchorElement;

  if (!link) return;

  const text = link.textContent === "" ? link.getAttribute("aria-label") : link.textContent?.trim();
  const anchorLink = link.href;

  if (!text || !anchorLink) return;

  const eventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.headerLink,
      eventLabel: `Header Link ${text} - ${anchorLink}`,
      eventValue: 1
    },
    subcategory: "Others",
    category: {
      primaryCategory: EventType.engagement
    }
  };

  pushEvent(eventData);
  logEvent(`Header Link ${text} - ${anchorLink}`, eventData);
};
