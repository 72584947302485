import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

/**
 * Article Click
 */
export const event4 = (e: MouseEvent) => {
  const link = (e.target as Element).closest("[data-analytics-event4]") as HTMLAnchorElement;
  if (!link) return;

  const text = link.textContent === "" ? link.getAttribute("aria-label") : link.textContent?.trim();
  const anchorLink = link.href;

  if (!text || !anchorLink) return;

  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.articleClick,
      eventLabel: `${text}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: EventType.read
  };

  pushEvent(EventData);
  logEvent("Article Click", EventData);
};
