import { buttonClickCustomEvent } from "./buttonClick";
import { event2 } from "./event2";
import { event3 } from "./event3";
import { event4 } from "./event4";
import { event47 } from "./event47";
import { event48 } from "./event48";
import { event57 } from "./event57";
import { event63 } from "./event63";
import { event78 } from "./event78";
import { faqClickCustomEvent } from "./faqClick";
import { handleScroll } from "./handleScroll";

const linkClickEvents = [
  buttonClickCustomEvent,
  faqClickCustomEvent,
  event78,
  event47,
  event2,
  event3,
  event4,
  event48,
  event57,
  event63
];

const bindClinkEvents = () => {
  linkClickEvents.forEach(eventFn => {
    document.addEventListener("click", eventFn);
  });
  document.addEventListener("scroll", handleScroll);
};

export default () => {
  if (typeof window !== "undefined") {
    window.onload = () => {
      bindClinkEvents();
    };
  }
};
