import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

/**
 * Anchor Link Click
 */
export const event3 = (e: MouseEvent) => {
  const link = (e.target as Element).closest('[data-analytics-event3]');
  // not an 'a', missing 'href' or is NOT a hash/anchor link to elsewhere on the current page

  if (!link) return;

  let text = "";
  if (link) {
    text = (link.getAttribute("aria-label") || link.textContent?.trim()) + " - " + (link?.href || '');
  }

  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.anchorLinkClicked,
      eventLabel: `${text}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    }
  }

  pushEvent(EventData);
  logEvent('Anchor Link Click', EventData);
};
