import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

// Button Click

export const buttonClickCustomEvent = (e: Event) => {
  const button = e.target.closest("[data-analytics-custombutton]");
  if (!button) return;

  const text = button.getAttribute("aria-label") || button.textContent.trim();

  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.custom,
      eventLabel: "Button Click - " + text,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.engagement
    },
    subcategory: EventType.custom
  };
  pushEvent(EventData);
  logEvent("Button Click - " + text, EventData);
};
