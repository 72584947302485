import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

// Faq Click

export const faqClickCustomEvent = (e: MouseEvent) => {
  const faq = (e.target as Element).closest("[data-analytics-customfaq]") as HTMLAnchorElement;

  if (!faq) return;
  const eventAction = faq.getAttribute("aria-label") || "";
  const text = faq.getAttribute("data-question") || faq.textContent?.trim();

  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: eventAction,
      eventLabel: text,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    },
    subcategory: EventType.custom
  };
  pushEvent(EventData);
  logEvent("Faq Click - " + text, EventData);
};
