/* eslint-disable no-console */

// Log event in development
export const logEvent = (actionType, analyticsEvent) => {
  if (typeof window !== `undefined`) {
    if (process.env.NODE_ENV !== "production") {
      const eventData = JSON.stringify(analyticsEvent);
      const digitalData = JSON.stringify(window.digitalData);
      console.log(actionType, JSON.parse(eventData), JSON.parse(digitalData));
    }
  }
};
