module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.tresemme.com","noQueryString":true},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tresemme US","short_name":"Tresemme US","start_url":"/us/en/","display":"standalone","icon":"/home/runner/work/tresemme-v2/tresemme-v2/workspaces/web/common/src/images/brand-icon/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b854c810268ac67b57944fe3675e16c4"},
    },{
      plugin: require('../../common/gatsby-browser.tsx'),
      options: {"plugins":[],"algoliaAppId":"","analyticsAdobeAnalyticsId":"unileverna-tresemme-us,unileverna-global-allbrands","analyticsAdobeLaunchScriptName":"e6bd1902389a/633dd490ad68/launch-ec8b08881b01.min.js","analyticsCountry":"US","analyticsGoogleAnalyticsId":"UA-40169962-1,G-W23PF8LZE6,G-K257S23T0D","analyticsLanguage":"en_US","analyticsLocalBrand":"Tresemme","binConstCo":"","binCartWire":"","cognitoPoolWebClientId":"","cognitoRegion":"","cognitoUserPoolId":"","contactUsBrandId":"BH0996","contactUsFormType":"twh","contactUsLocale":"en-us","contactUsEnv":"production","contactUsQCDeployment":"","contactUsQCLiveAgentInit":"","contactUsQCDeploymentId":"","contactUsQCOrgId":"","contactUsQCButtonId":"","extendedProfileApiUrl":"","fontsPath":"/us/en/fonts","htmlLang":"en-US","isoCountry":"US","isoLanguage":"EN","kritiqueApiKey":"","kritiqueBrandId":"","kritiqueLocaleId":"","kritiqueRegion":"","kritiqueSiteSource":"","locale":"en_US","pathPrefix":"/us/en/","miappiClientId":"","oneTrustDataDomainScriptId":"","prmApiUrl":"","prmBrandCode":"","prmCampaignId":"","epsilonUrl":"","epsilonDataSourceId":"","epsilonSignUpCCID":"","epsilonBrandCCID":"","epsilonCorporateCCID":"","reCaptchaSiteKey":"","sanityId":"4nopozul","siteMapExclusions":["/preview"],"smartLabel":true,"youtubeApiIframe":"https://www.youtube.com/iframe_api","cookieQscriptURL":"","cookieQlang":"","cookieQORG":"","evidonCompanyID":"2523","evidonOCID":"89054","additionalLanguagesConfig":[{"language":"es","analyticsLanguage":"es_US","contactUsLocale":"es-us","contactUsBrandId":"","htmlLang":"es-US","isoLanguage":"ES","epsilonDataSourceId":"","epsilonBrandCCID":"","kritiqueLocaleId":"","kritiqueSiteSource":"","locale":"es_US","country":"United States","languageString":"English"}]},
    }]
