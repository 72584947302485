import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

/**
 * Clicks to start Sign Up
 */
export const event57 = (e: MouseEvent) => {
  if (window?.registrationStarted) return;
  const input = (e.target as Element).closest("[data-analytics-event57]");
  if (!input) return;

  const pageURL = typeof window !== "undefined" ? window.location.href : "";
  if (!pageURL) return;

  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.SignUpstart,
      eventLabel: `${pageURL}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    }
  };
  pushEvent(EventData);
  logEvent("Clicks to start Sign Up", EventData);
  window.registrationStarted = true;
};
