import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

export const event47 = (e: MouseEvent) => {
  const link = (e.target as Element).closest("[data-analytics-event47]") as HTMLAnchorElement;

  if (!link) return;

  const text = link.textContent === "" ? link.getAttribute("aria-label") : link.textContent?.trim();

  const anchorLink = link.href;

  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.share,
      eventLabel: `${text} - ${anchorLink}`,
      eventValue: 1
    },
    category: { primaryCategory: EventType.referral },
    subcategory: "Lead"
  };

  pushEvent(EventData);
  logEvent(`Social Share Click ${text} - ${anchorLink}`, EventData);
};
