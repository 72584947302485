import React from "react";
import { WrapRootElementBrowserArgs } from "gatsby";
import loadEvents from "./src/analytics/loadEvent";
import { globalHistory } from '@reach/router'
import { event1 } from "./src/analytics/event1";
import { clearInterval } from "timers";


import "./src/styles/bootstrap.scss";
import "./src/styles/main.scss";

interface PluginOpts {
  cognitoRegion: string;
  cognitoUserPoolId: string;
  cognitoPoolWebClientId: string;
}

const urlWeb = new URL(window.location.href);
const allowScript = urlWeb.searchParams.get("enableAnalytics");

if(allowScript !== 'false'){
  loadEvents();
}

globalHistory.listen(() => {
  const event1Timer = setInterval(() => {
    if(window.isDigitalDataLoaded && allowScript !== 'false'){
      event1();
      window.isEvent1 = true;
      clearTimeout(event1Timer)
    }
  }, 500)
});

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs): React.ReactElement => {
  return <div>{element}</div>;
};
